import { ReactNode, forwardRef, ForwardedRef } from "react";
import { Label, LabelImage, LabelText, LabelType } from "./Label";
import CardHeaderLeft from "../../assets/card-header/card-header-left.svg";
import CardHeaderRight from "../../assets/card-header/card-header-right.svg";

interface CardProps {
  labelText?: string;
  labelLinkTo?: string;
  labelIcon?: string;
  labelType?: LabelType;
  children?: ReactNode;
  contentPadding?: "default" | "table" | "match-row" | "news" | "e2";
  contentClassName?: string;
  header?: boolean;
  transparent?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  wrapperClassName?: "default" | "e2" | "e2-web";
  wrapperStyle?: Record<string, string | number>;
}

function CardHeader({
  labelText,
  labelIcon,
  labelType = "card",
  labelLinkTo,
}: CardProps) {
  return (
    <div className="card-header">
      <CardHeaderLeft />
      <div className="card-header-bg">
        {labelText && (
          <Label type={labelType} linkTo={labelLinkTo}>
            <LabelImage url={labelIcon} />
            <LabelText>{labelText}</LabelText>
          </Label>
        )}
      </div>
      <CardHeaderRight />
    </div>
  );
}

interface CardWrapperProps {
  children?: ReactNode;
}

interface StickyCardWrapperProps extends CardWrapperProps {
  style?: Record<string, string | number>;
  className?: "default" | "e2" | "e2-web";
  children?: ReactNode;
}

function CardWrapper({ children }: CardWrapperProps) {
  return <div className="card-wrapper">{children}</div>;
}

const StickyCardWrapper = forwardRef(
  (
    {
      className: stickyTopPosition = "default",
      style: stickyTopCss,
      children,
    }: StickyCardWrapperProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className={`card-wrapper-sticky-${stickyTopPosition}`}
        style={stickyTopCss}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
StickyCardWrapper.displayName = "StickyCardWrapper";

function CardContent({
  labelText,
  labelIcon,
  labelType,
  labelLinkTo,
  children,
  contentPadding = "default",
  contentClassName = "",
  header = true,
  transparent = false,
  noPadding = false,
  noMargin = false,
}: CardProps) {
  return (
    <div
      className={`
          card 
          ${transparent ? "card-transparent" : ""} 
          ${noPadding ? "card-no-padding" : ""}
          ${noMargin ? "card-no-margin" : ""}`}
    >
      {header && !transparent && (
        <CardHeader
          labelIcon={labelIcon}
          labelText={labelText}
          labelType={labelType}
          labelLinkTo={labelLinkTo}
        />
      )}
      <div
        className={`
          card-content 
          card-content-${contentPadding} 
          ${contentClassName}
        `}
      >
        {children}
      </div>
    </div>
  );
}

export const StickyCard = forwardRef(
  (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <StickyCardWrapper
        className={props.wrapperClassName}
        style={props.wrapperStyle}
        ref={ref}
      >
        <CardContent {...props} />
      </StickyCardWrapper>
    );
  }
);
StickyCard.displayName = "StickyCard";

export default function Card(props: CardProps) {
  return (
    <CardWrapper>
      <CardContent {...props} />
    </CardWrapper>
  );
}
