import { useEffect, useRef, useState } from "react";
import { useEnvironment } from "../../Environment";
import { loadE2Script } from "../../oddsServe";
import {
  addContentBetsToIframe,
  addContentE2ToIframe,
} from "../../shared/e2helpers";
import { useIsBreakpointLg } from "../../shared/useBreakpoints";
import { useAdClickEffect } from "../../shared/useAdClickEffect";
import { StickyCard } from "../common/Card";
import { useE2Tipps } from "./E2TippsContext";

interface E2BarProps {
  matchId?: string;
  roundNumber?: number;
  reset?: boolean;
}

function E2Bar({ reset, roundNumber, matchId }: E2BarProps) {
  const env = useEnvironment();
  const consent = env?.consent ?? "";
  const { e2Tipps, resetE2Data } = useE2Tipps();

  const slotRef = useRef<HTMLDivElement>(null);
  const [e2Loaded, setE2Loaded] = useState(false);
  const isLg = useIsBreakpointLg();

  if (reset) {
    resetE2Data();
  }
  useAdClickEffect(slotRef, e2Loaded);

  useEffect(() => {
    // console.log("load e2");
    if (slotRef.current) {
      slotRef.current.innerHTML = "";
    }
    loadE2Script(consent, isLg, setE2Loaded);
  }, [consent, isLg, roundNumber, matchId]);

  useEffect(() => {
    if (e2Loaded) {
      // console.log("e2tipps changed", e2Tipps);
      addContentE2ToIframe(Object.values(!reset ? e2Tipps : {}), slotRef);
      addContentBetsToIframe(Object.values(!reset ? e2Tipps : {}));
    }
  }, [reset, e2Tipps, e2Loaded]);

  return <div ref={slotRef} id="renderSlot" />;
}

export function E2BarSticky(props: E2BarProps) {
  const ref = useRef(null);
  const disableAds = useEnvironment().disableAds;
  const env = useEnvironment();

  if (disableAds) {
    return <></>;
  }

  return (
    <StickyCard
      ref={ref}
      wrapperClassName={env.isWebApp ? "e2-web" : "e2"}
      wrapperStyle={{ marginLeft: "0px", marginRight: "0px" }}
      noMargin
      contentPadding="e2"
      header={false}
    >
      <E2Bar {...props} />
    </StickyCard>
  );
}
